import { v4 as uuidv4 } from 'uuid';

export class Payment {
    constructor(id, folio, objectType, objectId, type, amount, paidAmount, balance, businessEntity, method,
        reference, cancelNotes, createdBy, createdDate, paymentDate, status, accountsInTransaction, isVerified = false, checkedToVerify = false) {
        this.id = id;
        this.folio = folio;
        this.objectType = objectType;
        this.objectId = objectId;
        this.type = type;
        this.amount = amount;
        this.paidAmount = paidAmount;
        this.balance = balance;
        this.businessEntity = businessEntity;
        this.method = method;
        this.reference = reference;
        this.cancelNotes = cancelNotes;
        this.createdBy = createdBy;
        this.createdDate = createdDate;
        this.paymentDate = paymentDate;
        this.status = status;
        this.accountsInTransaction = accountsInTransaction;
        this.isVerified = isVerified;
        this.checkedToVerify = checkedToVerify;

        this.guid = uuidv4();
    }

    static create() {
        return new Payment(0, '', '', 0, '', '', '', '', null, '', '', '', '', '', '', '', 0);
    }
}