<template>
    <b-overlay :show="isLoading">
        <b-card class="white-bg">
            <template #header>
                <div class="row">
                    <div class="col-sm-12 text-right">
                        <div class="mt-2 font-italic">
                            Última actualización el <strong>{{ businessOrder.updatedDate ? businessOrder.updatedDate : businessOrder.createdDate }}</strong> por <strong>{{ businessOrder.updatedBy ? businessOrder.updatedBy : businessOrder.createdBy }}</strong>
                        </div>
                    </div>
                </div>
            </template>
            <b-row class="mt-2">
                <b-col class="col-md-6 col-lg-6">
                    <b-table-simple responsive bordered striped>
                        <b-tbody>
                            <b-tr>
                                <b-th style="width: 35%">Registrado</b-th>
                                <b-td>{{ businessOrder.createdDate ? businessOrder.createdDate : 'Sin registro' }}</b-td>
                            </b-tr>
                            <b-tr>
                                <template v-if="businessOrder && businessOrder.branch">
                                    <b-th>Sucursal</b-th>
                                    <b-td>{{ businessOrder.branch.name }}</b-td>
                                </template>
                            </b-tr>
                            <b-tr>
                                <b-th>Elaborado por</b-th>
                                <b-td>{{ businessOrder.createdBy ? businessOrder.createdBy : 'Sin registro' }}</b-td>
                            </b-tr>
                            <b-tr v-if="businessOrder.processedDate">
                                <b-th>Procesado</b-th>
                                <b-td>{{ businessOrder.processedDate }} por {{ businessOrder.processedBy }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th>Folio del cliente (agencia)</b-th>
                                <b-td>{{ businessOrder.customerFolio }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th>Pasajeros</b-th>
                                <b-td>Adultos: {{ businessOrder.adultsQty }} / Niños: {{ businessOrder.childrenQty }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th>Total</b-th>
                                <b-td>$ {{ formatCurrency(businessOrder.total) }}</b-td>
                            </b-tr>
                            <template v-if="businessOrder.status === 'CANCELLED'">
                                <b-tr>
                                    <b-th>Fecha de cancelación</b-th>
                                    <b-td>{{ businessOrder.cancelledDate ? businessOrder.cancelledDate : 'Sin registro' }}</b-td>
                                </b-tr>
                                <b-tr>
                                    <b-th>Cancelado por</b-th>
                                    <b-td>{{ businessOrder.cancelledBy ? businessOrder.cancelledBy : 'Sin registro' }}</b-td>
                                </b-tr>
                                <b-tr>
                                    <b-th>Motivo de la cancelación</b-th>
                                    <b-td>{{ businessOrder.cancelNotes ? businessOrder.cancelNotes : 'Sin registro' }}</b-td>
                                </b-tr>
                            </template>
                        </b-tbody>
                    </b-table-simple>
                </b-col>
                <b-col class="col-md-6 col-lg-6 text-right">
                    <h1 class="font-weight-bolder">Reservación</h1>
                    <h1 class="text-navy font-weight-bolder">{{ businessOrder.folio }}</h1>
                    <h3 class="mb-3">
                        <b-badge variant="warning" class="button-sticky-right" v-if="businessOrder.status === 'DRAFT'">Borrador</b-badge>
                        <b-badge variant="info" class="button-sticky-right" v-if="businessOrder.status === 'OPEN'">Abierta</b-badge>
                        <b-badge variant="primary" class="button-sticky-right" v-if="businessOrder.status === 'CLOSED'">Cerrada</b-badge>
                        <b-badge variant="danger" class="button-sticky-right" v-if="businessOrder.status === 'CANCELLED'">Cancelada</b-badge>
                    </h3>
                    <h3 class="mb-3" v-if="businessOrder.status === 'OPEN' || businessOrder.status === 'CLOSED'">
                        <b-badge variant="warning" class="button-sticky-right" v-if="!businessOrder.isPaid">Por saldar</b-badge>
                        <b-badge variant="primary" class="button-sticky-right" v-if="businessOrder.isPaid">Pagada</b-badge>
                    </h3>
                </b-col>
            </b-row>
            <hr>
            <b-row class="mt-4">
                <b-col>
                    <b-table-simple responsive bordered striped>
                        <b-thead>
                            <b-tr>
                                <b-th colspan="4">Cliente</b-th>
                            </b-tr>
                            <b-tr class="table-header-color">
                                <b-th colspan="4">Tipo</b-th>
                            </b-tr>
                        </b-thead>
                        <b-tbody>
                            <b-tr>
                                <b-td colspan="4">Persona {{ businessOrder.businessEntity.type === 'FISICA' ? 'Física' : 'Moral' }}</b-td>
                            </b-tr>
                            <template v-if="businessOrder.businessEntity.type === 'FISICA'">
                                <b-tr class="table-header-color">
                                    <b-th>Nombre</b-th>
                                    <b-th>Apellido paterno</b-th>
                                    <b-th>Apellido materno</b-th>
                                    <b-th>RFC</b-th>
                                </b-tr>
                                <b-tr>
                                    <b-td>{{ businessOrder.businessEntity.name }}</b-td>
                                    <b-td>{{ businessOrder.businessEntity.firstLastName }}</b-td>
                                    <b-td>{{ businessOrder.businessEntity.secondLastName }}</b-td>
                                    <b-td>{{ businessOrder.businessEntity.rfc }}</b-td>
                                </b-tr>
                            </template>
                            <template v-else>
                                <b-tr class="table-header-color">
                                    <b-th colspan="2">Razón social</b-th>
                                    <b-th>Régimen capital</b-th>
                                    <b-th>RFC</b-th>
                                </b-tr>
                                <b-tr>
                                    <b-td colspan="2">{{ businessOrder.businessEntity.legalName }}</b-td>
                                    <b-td>{{ businessOrder.businessEntity.legalEntityType }}</b-td>
                                    <b-td>{{ businessOrder.businessEntity.rfc }}</b-td>
                                </b-tr>
                            </template>
                            <b-tr class="table-header-color">
                                <b-th colspan="4">Nombre comercial</b-th>
                            </b-tr>
                            <b-tr>
                                <b-td colspan="4">{{ businessOrder.businessEntity.commercialName ? businessOrder.businessEntity.commercialName : 'Sin registro' }}</b-td>
                            </b-tr>
                            <template v-if="businessOrder.businessEntity.type === 'MORAL'">
                                <b-tr class="table-header-color">
                                    <b-th>Nombre(s) del contacto</b-th>
                                    <b-th>Apellido paterno del contacto</b-th>
                                    <b-th>Apellido materno del contacto</b-th>
                                    <b-th></b-th>
                                </b-tr>
                                <b-tr>
                                    <b-td>{{ businessOrder.businessEntity.name }}</b-td>
                                    <b-td>{{ businessOrder.businessEntity.firstLastName }}</b-td>
                                    <b-td>{{ businessOrder.businessEntity.secondLastName }}</b-td>
                                    <b-td></b-td>
                                </b-tr>
                            </template>
                            <b-tr class="table-header-color">
                                <b-th style="width: 25%;">Teléfono 1</b-th>
                                <b-th style="width: 25%;">Teléfono 2</b-th>
                                <b-th style="width: 25%;">Email 1</b-th>
                                <b-th style="width: 25%;">Email 2</b-th>
                            </b-tr>
                            <b-tr>
                                <b-td>{{ businessOrder.businessEntity.phone1 ? businessOrder.businessEntity.phone1 : 'Sin registro' }}</b-td>
                                <b-td>{{ businessOrder.businessEntity.phone2 ? businessOrder.businessEntity.phone2 : 'Sin registro' }}</b-td>
                                <b-td>{{ businessOrder.businessEntity.email1 ? businessOrder.businessEntity.email1 : 'Sin registro'}}</b-td>
                                <b-td>{{ businessOrder.businessEntity.email2 ? businessOrder.businessEntity.email2 : 'Sin registro' }}</b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </b-col>
            </b-row>
            <hr>
            <b-row class="mt-4">
                <b-col>
                    <b-table-simple responsive bordered striped>
                        <b-thead>
                            <b-tr>
                                <b-th class="table-header-color" colspan="3">Pasajeros</b-th>
                            </b-tr>
                            <b-tr>
                                <b-th class="text-center" style="width: 6%">PAX</b-th>
                                <b-th>Tipo</b-th>
                                <b-th>Nombre</b-th>
                            </b-tr>
                        </b-thead>
                        <b-tbody>
                            <b-tr v-for="(passenger, index) in businessOrder.passengers" :key="passenger.guid">
                                <b-td class="text-center">{{ index + 1 }}</b-td>
                                <b-td>{{ passenger.type === 'ADULT' ? 'Adulto' : 'Niño' }}</b-td>
                                <b-td style="min-width: 125px">{{ passenger.name }} {{ passenger.firstLastName }}</b-td>
                            </b-tr>
                        </b-tbody>
                        <b-tfoot>
                            <b-tr>
                                <b-th>Adultos</b-th>
                                <b-th class="text-right font-weight-bold" style="width: 6%;">{{ businessOrder.adultsQty }}</b-th>
                            </b-tr>
                            <b-tr>
                                <b-th>Niños</b-th>
                                <b-th class="text-right font-weight-bold" style="width: 6%;">{{ businessOrder.childrenQty }}</b-th>
                            </b-tr>
                        </b-tfoot>
                    </b-table-simple>
                </b-col>
            </b-row>
            <hr>
            <b-row class="mt-4">
                <b-col>
                    <b-table-simple responsive bordered striped>
                        <b-thead>
                            <b-tr>
                                <b-th class="table-header-color" colspan="5">Partidas</b-th>
                            </b-tr>
                            <b-tr>
                                <b-th class="text-center" style="width: 30px">#</b-th>
                                <b-th>Concepto</b-th>
                                <b-th style="width: 8%" class="text-right">Cantidad</b-th>
                                <b-th style="width: 11%" class="text-right">Precio Unitario</b-th>
                                <b-th style="width: 11%" class="text-right">Subtotal</b-th>
                            </b-tr>
                        </b-thead>
                        <b-tbody>
                            <b-tr v-for="(item, index) in businessOrder.businessOrderItems" :key="item.guid" :variant="item.isDeleted() ? 'danger' : ''">
                                <b-td :class="['text-center', { 'text-danger': item.isDeleted() }]">{{ index + 1 }} {{ item.isDeleted() ? 'Eliminado' : '' }}</b-td>
                                <b-td>
                                    <b-row v-if="item.service">
                                        <b-col md="12">
                                            <span class="font-weight-bold">{{ item.entityType === 'TOUR' ? 'Tour' : 'Traslado' }}: </span> {{ item.name }}<br>
                                            <template v-if="item.entityType === 'TOUR'"><span class="font-weight-bold">Duración: </span>{{ item.duration }} Día(s)<br></template>
                                            <span class="font-weight-bold">Tipo: </span> {{ item.type === 'COLLECTIVE' ? 'Colectivo' : 'Exclusivo' }}<br>
                                        </b-col>
                                    </b-row>
                                    <b-row class="mt-2" v-if="item.service">
                                        <b-col md="3" class="position-static">
                                            <label class="mb-0">{{ (item.entityType === '' || item.entityType === 'TOUR' ? 'Fecha de inicio' : 'Fecha') }}</label>
                                            <span>{{ item.startDate ? item.startDate : 'Sin registro' }}</span>
                                        </b-col>
                                        <b-col md="3" class="position-static" v-if="item.entityType === '' || item.entityType === 'TOUR'">
                                            <label class="mb-0">Fecha de término</label>
                                            <span>{{ item.endDate ? item.endDate : 'Sin registro' }}</span>
                                        </b-col>
                                        <template v-if="item.entityType === 'TRANSFER'">
                                            <b-col md="3">
                                                <label class="mb-0">Horario de vuelo</label>
                                                <span>{{ item.flightSchedule ? item.flightSchedule : 'Sin registro' }}</span>
                                            </b-col>
                                            <b-col md="3">
                                                <label class="mb-0">Número de vuelo</label>
                                                <span>{{ item.flightNumber ? item.flightNumber : 'Sin registro' }}</span>
                                            </b-col>
                                            <b-col md="3">
                                                <label class="mb-0">Hotel</label>
                                                <span>{{ item.hotel1 ? item.hotel1 : 'Sin registro' }}</span>
                                            </b-col>
                                        </template>
                                        <template v-if="item.entityType === 'TOUR'">
                                            <b-col md="3">
                                                <label class="mb-0">Recoger en hotel</label>
                                                <span>{{ item.hotel1 ? item.hotel1 : 'Sin registro' }}</span>
                                            </b-col>
                                            <b-col md="3">
                                                <label class="mb-0">Dejar en hotel</label>
                                                <span>{{ item.hotel2 ? item.hotel2 : 'Sin registro' }}</span>
                                            </b-col>
                                        </template>
                                    </b-row>
                                    <b-row class="mt-2">
                                        <b-col md="12">
                                            <label class="mb-0">Notas</label>
                                            <span>{{ item.notes ? item.notes : 'Sin registro' }}</span>
                                        </b-col>
                                    </b-row>
                                    <b-row class="mt-2">
                                        <b-col>
                                            <b-table-simple responsive bordered>
                                                <b-thead>
                                                    <b-tr>
                                                        <b-th colspan="3">
                                                            Pasajeros
                                                        </b-th>
                                                    </b-tr>
                                                </b-thead>
                                                <b-tbody>
                                                    <b-tr v-for="passenger in item.passengers" :key="passenger.guid">
                                                        <template v-if="passenger.selected">
                                                            <td colspan="1" style="width: 8%">{{ passenger.type === 'ADULT' ? 'Adulto' : 'Niño' }}</td>
                                                            <td colspan="1"><router-link :to="{ name: 'ServiceOrderShow', params: { id: passenger.serviceOrderId }}" target="_blank">{{ passenger.folio }}</router-link> {{ passenger.name }} {{ passenger.firstLastName }} {{ passenger.secondLastName }} <span class="text-danger" v-if="passenger.cancel">(Cancelado)</span></td>
                                                        </template>
                                                    </b-tr>
                                                </b-tbody>
                                            </b-table-simple>
                                        </b-col>
                                    </b-row>
                                    <template v-if="item.cancelDescription">
                                        <b-row>
                                            <b-col md="12">
                                                <label class="mb-0">Motivo de la cancelación</label>
                                                <p>{{ item.cancelDescription }}</p>
                                            </b-col>
                                        </b-row>
                                    </template>
                                </b-td>
                                <b-td class="text-right">
                                    <b-row v-if="item.service">
                                        <b-col md="12">
                                            <template v-if="item.adults && item.type === 'COLLECTIVE'">
                                                <span class="font-weight-bold">Adultos: </span>{{ item.adults }}<br>
                                            </template>
                                            <template v-if="item.children && item.type === 'COLLECTIVE'">
                                                <span class="font-weight-bold">Niños: </span>{{ item.children }}
                                            </template>
                                            <template v-if="item.type === 'EXCLUSIVE'">1</template>
                                        </b-col>
                                    </b-row>
                                </b-td>
                                <b-td class="text-right">
                                    <b-row v-if="item.service">
                                        <b-col md="12">
                                            <template v-if="item.adults && item.type === 'COLLECTIVE'">
                                                <span class="font-weight-bold">Adulto: </span>$ {{ formatCurrency(item.adultsFare) }}<br>
                                            </template>
                                            <template v-if="item.children && item.type === 'COLLECTIVE'">
                                                <span class="font-weight-bold">Niño: </span>$ {{ formatCurrency(item.childFare) }}
                                            </template>
                                            <template v-if="item.type === 'EXCLUSIVE'">
                                                <span class="font-weight-bold">Servicio: </span>$ {{ formatCurrency(item.serviceFare) }}
                                            </template>
                                        </b-col>
                                    </b-row>
                                </b-td>
                                <b-td class="text-right">
                                    <b-row>
                                        <b-col md="12">
                                            <template v-if="item.adults && item.type === 'COLLECTIVE'">
                                                $ {{ formatCurrency(item.adultsFare * item.adults) }}<br>
                                            </template>
                                            <template v-if="item.children && item.type === 'COLLECTIVE'">
                                                $ {{ formatCurrency(item.childFare * item.children) }}<br>
                                            </template>
                                            <span class="font-weight-bold">Subtotal: </span>$ {{ formatCurrency(item.total) }}
                                        </b-col>
                                    </b-row>
                                </b-td>
                            </b-tr>
                        </b-tbody>
                        <b-tfoot>
                            <b-tr>
                                <b-th colspan="4" class="text-right">Total</b-th>
                                <b-th class="text-right">$ {{ formatCurrency(businessOrder.total) }}</b-th>
                            </b-tr>
                            <b-tr>
                                <b-th colspan="4" class="text-right">Pagado</b-th>
                                <b-th class="text-right">$ {{ formatCurrency(paymentsAmount) }}</b-th>
                            </b-tr>
                            <b-tr>
                                <b-th colspan="4" class="text-right">Saldo</b-th>
                                <b-th class="text-right">
                                    <template v-if="businessOrder.isPaid"><b-badge variant="primary">PAGADA</b-badge></template>
                                    <template v-else>$ {{ formatCurrency(businessOrder.total - paymentsAmount) }}</template>
                                </b-th>
                            </b-tr>
                        </b-tfoot>
                    </b-table-simple>
                </b-col>
            </b-row>
            <template v-if="businessOrder.status !== 'DRAFT'">
                <hr>
                <b-row class="mt-4">
                    <b-col>
                        <b-table-simple responsive bordered striped class="z-index-1">
                            <b-thead>
                                <b-tr>
                                    <b-th class="table-header-color" colspan="10">Cuenta por cobrar</b-th>
                                </b-tr>
                                <b-tr>
                                    <b-th>Folio</b-th>
                                    <b-th>Importe</b-th>
                                    <b-th>Pagado</b-th>
                                    <b-th>Saldo</b-th>
                                    <b-th>Estatus</b-th>
                                </b-tr>
                            </b-thead>
                            <b-tbody>
                                <b-td><router-link :to="{ name: 'AccountRpShow', params: { id: businessOrder.accountRp.id }}" target="_blank">{{ businessOrder.accountRp.folio }}</router-link></b-td>
                                <b-td class="text-right">$ {{ formatCurrency(businessOrder.accountRp.amount) }}</b-td>
                                <b-td class="text-right">$ {{ formatCurrency(businessOrder.accountRp.paidReceiveAmount) }}</b-td>
                                <b-td class="text-right">$ {{ formatCurrency(businessOrder.accountRp.balance) }}</b-td>
                                <b-td class="text-center">
                                    <b-badge variant="warning" v-if="businessOrder.accountRp.status === 'BALANCE'">Por saldar</b-badge>
                                    <b-badge variant="primary" v-if="businessOrder.accountRp.status === 'PAID'">Pagada</b-badge>
                                    <b-badge variant="danger" v-if="businessOrder.accountRp.status === 'CANCELLED'">Cancelada</b-badge>
                                </b-td>
                            </b-tbody>
                        </b-table-simple>
                    </b-col>
                </b-row>
                <b-row class="mt-2">
                    <b-col>
                        <ValidationObserver ref="observer" v-slot="{ passes }">
                            <b-form @submit.prevent="passes(savePayment)" id="payment-form">
                                <b-button class="button-sticky-right mb-3" @click="addPayment" v-if="!businessOrder.isPaid">
                                    <font-awesome-icon icon="plus"></font-awesome-icon> Nuevo Pago
                                </b-button>
                                <b-table-simple responsive bordered striped class="z-index-1">
                                    <b-thead>
                                        <b-tr>
                                            <b-th class="table-header-color" colspan="10">Pagos</b-th>
                                        </b-tr>
                                        <b-tr>
                                            <b-th class="text-center" style="width: 4%">#</b-th>
                                            <b-th style="width: 10%; min-width: 150px;">Fecha de pago</b-th>
                                            <b-th>Folio</b-th>
                                            <b-th style="width: 12%; min-width: 150px;">Método</b-th>
                                            <b-th>Registrado por</b-th>
                                            <b-th>Fecha de registro</b-th>
                                            <b-th>Transacción</b-th>
                                            <b-th>Estatus</b-th>
                                            <b-th style="text-align: right; width: 12%; min-width: 90px; max-width: 120px;">Importe</b-th>
                                            <b-th class="text-center">Acciones</b-th>
                                        </b-tr>
                                    </b-thead>
                                    <b-tbody>
                                        <b-tr v-for="(payment, index) in businessOrder.payments" :key="'payment-' + index" :class="{'text-danger': payment.status === 'CANCELLED'}" :style="[payment.status === 'CANCELLED' ? {'text-decoration': 'line-through'} : '']">
                                            <template v-if="payment.id">
                                                <b-td class="text-center">{{ index + 1 }}</b-td>
                                                <b-td>{{ formatDate(payment.paymentDate) }}</b-td>
                                                <b-td><router-link :to="{ name: 'PaymentShow', params: { id: payment.id }}" target="_blank">{{ payment.folio }}</router-link></b-td>
                                                <b-td>
                                                    <span v-if="payment.method === 'CREDIT_CARD'">Tarjeta de crédito</span>
                                                    <span v-if="payment.method === 'DEBIT_CARD'">Tarjeta de débito</span>
                                                    <span v-if="payment.method === 'CASH'">Efectivo</span>
                                                    <span v-if="payment.method === 'CHECK'">Cheque</span>
                                                    <span v-if="payment.method === 'TRANSFER'">Transferencia</span>
<!--                                                    <span v-if="payment.method === 'OTHER'">Otros</span>-->
                                                </b-td>
                                                <b-td>{{ payment.createdBy }}</b-td>
                                                <b-td>{{ formatDateTime(payment.createdDate) }}</b-td>
                                                <b-td>
                                                    {{ payment.transactionFolio }}
                                                    <b-button size="sm" variant="outline-primary" v-b-tooltip.hover.top title="Imprimir"
                                                              @click="transactionPage(payment)" v-if="payment.accountsInTransaction > 1">
                                                        <font-awesome-icon icon="print"></font-awesome-icon>
                                                    </b-button>
                                                </b-td>
                                                <b-td>
                                                    <span v-if="payment.status === 'TO_VERIFY'">Por verificar</span>
                                                    <span v-if="payment.status === 'VERIFIED'">Verificado</span>
                                                    <span v-if="payment.status === 'CANCELLED'">Cancelado</span>
                                                </b-td>
                                                <b-td style="text-align: right">${{ formatCurrency(payment.paidAmount) }}</b-td>
                                                <b-td class="text-center">
                                                    <b-button-group size="sm">
                                                        <b-button variant="outline-info" :to="{ name: 'PaymentShow', params: { id: payment.id }}"
                                                                  v-b-tooltip.hover.top title="Detalle">
                                                            <font-awesome-icon icon="search"></font-awesome-icon>
                                                        </b-button>
                                                        <b-button v-if="payment.status === 'TO_VERIFY'" variant="outline-info"
                                                                  @click="verifyPayment(payment)" v-b-tooltip.hover.top title="Verificar">
                                                            <font-awesome-icon icon="check"></font-awesome-icon>
                                                        </b-button>
                                                        <b-button variant="outline-primary" @click="paymentPage(payment)"
                                                                  v-b-tooltip.hover.top title="Imprimir">
                                                            <font-awesome-icon icon="print"></font-awesome-icon>
                                                        </b-button>
                                                        <b-button v-if="payment.status !== 'CANCELLED'" variant="outline-danger"
                                                                  @click="cancelPayment(payment)" v-b-tooltip.hover.top title="Cancelar">
                                                            <font-awesome-icon icon="ban"></font-awesome-icon>
                                                        </b-button>
                                                    </b-button-group>
                                                </b-td>
                                            </template>
                                            <template v-if="!payment.id">
                                                <b-td></b-td>
                                                <b-td>
                                                    <ValidationProvider rules="required" v-slot="{ errors }">
                                                        <b-form-group invalid-feedback="Selecciona una fecha">
                                                            <b-form-datepicker v-model="payment.paymentDate" boundary="window" placeholder="Fecha de pago" v-bind="datePickerLabels" locale="es"
                                                                               :state="errors[0] ? false : null" hide-header></b-form-datepicker>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-td>
                                                <b-td></b-td>
                                                <b-td>
                                                    <ValidationProvider rules="required" v-slot="{ errors }">
                                                        <b-form-group invalid-feedback="Selecciona un método" :state="errors[0] ? false : null">
                                                            <v-select :options="methodTypes" v-model="payment.method" placeholder="Método"
                                                                      :reduce="method => method.value" label="text" append-to-body :clearable="false" class="test">
                                                                <template #no-options>Sin resultados.</template>
                                                            </v-select>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-td>
                                                <b-td></b-td>
                                                <b-td></b-td>
                                                <b-td></b-td>
                                                <b-td></b-td>
                                                <b-td>
                                                    <ValidationProvider rules="required|double" v-slot="{ errors }">
                                                        <b-form-group invalid-feedback="Ingresa el importe">
                                                            <b-form-input v-model="payment.amount" autocomplete="false" :placeholder="`Importe`" style="text-align: right"
                                                                          @keyup="calculatePayment(payment)" :state="errors[0] ? false : null"></b-form-input>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-td>
                                                <b-td class="text-center">
                                                    <b-button variant="outline-danger" @click="deletePayment(payment)" v-b-tooltip.hover.top title="Eliminar">
                                                        <font-awesome-icon icon="trash-alt"></font-awesome-icon>
                                                    </b-button>
                                                </b-td>
                                            </template>
                                        </b-tr>
                                        <b-tr v-if="businessOrder.payments.length === 0">
                                            <b-td colspan="10" class="text-center font-weight-bold">Sin registros</b-td>
                                        </b-tr>
                                    </b-tbody>
                                </b-table-simple>
                                <div class="text-right">
                                    <b-button @click="validateForm" variant="primary"><font-awesome-icon icon="save"></font-awesome-icon> Guardar pagos</b-button>
                                </div>
                            </b-form>
                        </ValidationObserver>
                    </b-col>
                </b-row>
            </template>
            <template #footer>
                <div class="text-right">
                    <b-button variant="outline-secondary" :to="{ name: 'BusinessOrderIndex' }"><font-awesome-icon icon="angle-left"></font-awesome-icon> Regresar</b-button>
                    <b-button variant="secondary" class="ml-2" :to="{ name: 'BusinessOrderEdit', params: { id: businessOrder.id }}" v-if="businessOrder.id && (businessOrder.status === 'DRAFT' || businessOrder.status === 'OPEN')">
                        <font-awesome-icon icon="edit"></font-awesome-icon> Modificar
                    </b-button>
                    <b-button class="ml-2" @click="closeBusinessOrder" variant="primary" v-if="businessOrder.status === 'OPEN'">
                        <font-awesome-icon icon="check"></font-awesome-icon> Cerrar
                    </b-button>
                    <b-button class="ml-2" variant="danger" @click="cancelBusinessOrder" v-if="businessOrder.status === 'DRAFT' || businessOrder.status === 'OPEN'"> Cancelar</b-button>
                    <b-button class="ml-2" variant="primary" @click="businessOrderPage"><font-awesome-icon icon="print"></font-awesome-icon> Imprimir</b-button>
                </div>
            </template>
        </b-card>
    </b-overlay>
</template>

<script>
import { businessOrderUrl, businessOrderCancelUrl, paymentUrl, paymentPageUrl, paymentCancelUrl, paymentVerifyUrl,
    businessOrderPageUrl, transactionPageUrl } from '@routes';
import * as constants from '@constants';
import { Passenger } from '../../models/passenger';
import { BusinessOrderItem } from '../../models/businessOrderItem';
import { BusinessEntity } from '../../models/businessEntity';
import { formatCurrency, formatDate, formatDateTime } from '../../js/utilities';
import { datePickerLabels } from '../../i18n/datePickerI18n';
import { Payment } from '../../models/payment';
import { required, double } from 'vee-validate/dist/rules';
import { extend } from 'vee-validate';

extend('required', {
    ...required,
    message: 'Campo requerido'
});

extend('double', {
    ...double,
    message: 'Ingrese solo números y decimales'
});

export default {
    data() {
        return {
            businessOrder: {
                id: 0,
                folio: '',
                createdDate: '',
                createdBy: '',
                updatedDate: '',
                updatedBy: '',
                processedDate: '',
                processedBy: '',
                cancelledDate: '',
                cancelledBy: '',
                cancelNotes: '',
                branch: null,
                businessEntity: {},
                adultsQty: 0,
                childrenQty: 0,
                customerFolio: '',
                total: 0,
                notes: '',
                isPaid: '',
                businessOrderItems: [],
                passengers: [],
                payments: [],
                accountRp: {}
            },
            paymentsAmount: 0,
            methodTypes: [
                { text: 'Efectivo', value: 'CASH' },
                { text: 'Tarjeta de débito', value: 'DEBIT_CARD' },
                { text: 'Tarjeta de crédito', value: 'CREDIT_CARD' },
                { text: 'Transferencia', value: 'TRANSFER' },
                { text: 'Cheque', value: 'CHECK' }
            ],
            datePickerLabels: datePickerLabels.es,
            breadcrumb: {
                title: 'Reservaciones',
                path: [
                    {
                        name: 'BusinessOrderIndex',
                        text: 'Reservaciones'
                    }
                ]
            },
            swalTitle: 'Reservaciones',
            isLoading: false
        };
    },
    created() {
        this.businessOrder.businessEntity = BusinessEntity.create();

        this.loadData();

        this.breadcrumb.path.push({
            name: this.$route.name,
            text: `Detalle`
        });

        this.$emit('update-breadcrumb', this.breadcrumb);
    },
    methods: {
        formatCurrency,
        formatDate,
        formatDateTime,
        loadData() {
            this.isLoading = true;

            this.axios.get(businessOrderUrl(this.$route.params.id)).then(response => {
                if (response.data.code === constants.CODE_OK) {
                    this.businessOrder = response.data.businessOrder;

                    response.data.passengers.forEach(passengerData => {
                        let passenger = Passenger.create(passengerData.type);

                        passenger.id = passengerData.id;
                        passenger.name = passengerData.name;
                        passenger.firstLastName = passengerData.firstLastName;
                        passenger.secondLastName = passengerData.secondLastName;
                        passenger.identifier = passengerData.id.toString();

                        this.businessOrder.passengers.push(passenger);
                    });

                    response.data.businessOrderItems.forEach(businessOrderItemData => {
                        let businessOrderItem = BusinessOrderItem.create();

                        businessOrderItem.id = businessOrderItemData.id;
                        businessOrderItem.name = businessOrderItemData.name;
                        businessOrderItem.description = businessOrderItemData.description;
                        businessOrderItem.type = businessOrderItemData.type;
                        businessOrderItem.adults = businessOrderItemData.adults;
                        businessOrderItem.adultsFare = businessOrderItemData.adultsFare;
                        businessOrderItem.children = businessOrderItemData.children;
                        businessOrderItem.childFare = businessOrderItemData.childFare;
                        businessOrderItem.serviceFare = businessOrderItemData.serviceFare;
                        businessOrderItem.total = businessOrderItemData.total;
                        businessOrderItem.startDate = businessOrderItemData.startDate;
                        businessOrderItem.endDate = businessOrderItemData.endDate;
                        businessOrderItem.hotel1 = businessOrderItemData.hotel1;
                        businessOrderItem.hotel2 = businessOrderItemData.hotel2;
                        businessOrderItem.flightNumber = businessOrderItemData.flightNumber;
                        businessOrderItem.flightSchedule = businessOrderItemData.flightSchedule;
                        businessOrderItem.notes = businessOrderItemData.notes;
                        businessOrderItem.cancelDescription = businessOrderItemData.cancelDescription;
                        businessOrderItem.status = businessOrderItemData.status;
                        businessOrderItem.passengers = [];
                        businessOrderItem.allPassengers = false;
                        businessOrderItem.service = {
                            id: businessOrderItemData.serviceId,
                            name: businessOrderItemData.name,
                            isCollective: businessOrderItemData.serviceCollective,
                            isExclusive: businessOrderItemData.serviceExclusive
                        };
                        businessOrderItem.duration = businessOrderItemData.duration;
                        businessOrderItem.entityType = businessOrderItemData.entityType;

                        let businessOrderItemPassengers = JSON.parse(businessOrderItemData.passengersData);

                        businessOrderItemPassengers.forEach(passengerData => {
                            let passenger = Passenger.create(passengerData.type);

                            passenger.id = passengerData.id;
                            passenger.name = passengerData.name;
                            passenger.firstLastName = passengerData.firstLastName;
                            passenger.secondLastName = passengerData.secondLastName;
                            passenger.identifier = passengerData.id.toString();
                            passenger.selected = passengerData.selected;
                            passenger.cancel = passengerData.cancel;
                            passenger.serviceOrderId = passengerData.serviceOrderId;

                            let serviceOrder = response.data.serviceOrders.filter(serviceOrder => serviceOrder.id == passenger.serviceOrderId);

                            if (serviceOrder[0]) {
                                passenger.folio = serviceOrder[0].folio;
                            }

                            businessOrderItem.passengers.push(passenger);
                        });

                        let selectedPassengers = businessOrderItem.passengers.filter(passenger => passenger.selected);

                        if (selectedPassengers.length === this.businessOrder.passengers.length) {
                            businessOrderItem.allPassengers = true;
                        }

                        this.businessOrder.businessOrderItems.push(businessOrderItem);
                    });

                    this.paymentsAmount = 0;
                    response.data.payments.forEach(paymentData => {
                        let payment = Payment.create();

                        payment.id = paymentData.id;
                        payment.folio = paymentData.folio;
                        // payment.objectType = paymentData.objectType;
                        // payment.objectId = paymentData.objectId;
                        payment.type = paymentData.type;
                        payment.amount = paymentData.amount;
                        payment.paidAmount = paymentData.paidAmount;
                        payment.balance = paymentData.balance;
                        payment.businessEntity = {
                            id: paymentData.businessEntityId,
                            type: paymentData.businessEntityType,
                            legalName: paymentData.businessEntityLegalName,
                            rfc: paymentData.businessEntityRfc,
                            phone1: paymentData.businessEntityPhone1,
                            email1: paymentData.businessEntityEmail1
                        };
                        payment.method = paymentData.method;
                        payment.reference = paymentData.reference;
                        payment.cancelNotes = paymentData.cancelNotes;
                        payment.createdBy = paymentData.createdBy;
                        payment.createdDate = paymentData.createdDate;
                        payment.paymentDate = paymentData.paymentDate;
                        payment.isVerified = paymentData.isVerified;
                        payment.transactionId = paymentData.transactionId;
                        payment.transactionFolio = paymentData.transactionFolio;
                        payment.accountsInTransaction = paymentData.accountsInTransaction;
                        payment.status = paymentData.status;

                        if (payment.status !== 'CANCELLED') {
                            this.paymentsAmount = this.paymentsAmount + parseFloat(payment.paidAmount);
                        }

                        this.businessOrder.payments.push(payment);
                    });
                }

                this.$nextTick(() => this.isLoading = false);
            });
        },
        addPayment() {
            let payment = Payment.create();

            payment.objectType = 'BusinessOrder';
            payment.objectId = this.businessOrder.id;
            payment.type = 'INCOME';
            payment.businessEntity = {
                id: this.businessOrder.businessEntity.id,
                type: 'CUSTOMER',
                legalName: this.businessOrder.businessEntity.legalName,
                rfc: this.businessOrder.businessEntity.rfc,
                phone1: this.businessOrder.businessEntity.phone1,
                email1: this.businessOrder.businessEntity.email1
            };

            this.businessOrder.payments.push(payment);
        },
        deletePayment(payment) {
            if (payment.id > 0) {
                payment.status = 'DELETED';
            } else {
                let index = this.businessOrder.payments.findIndex(expenseItem => expenseItem.guid === payment.guid);

                if (index >= 0) {
                    this.businessOrder.payments.splice(index, 1);
                }
            }

            this.paymentsAmount = this.paymentsAmount - payment.amount;
        },
        calculatePayment(payment) {
            payment.balance = 0;
            payment.paidAmount = payment.amount;
            this.paymentsAmount = 0;

            this.businessOrder.payments.forEach(data => {
                if (data.status !== 'CANCELLED') {
                    this.paymentsAmount = parseFloat(data.paidAmount) + this.paymentsAmount;
                }
            });
        },
        verifyPayment(payment) {
            let title = '¿Estas seguro de verificar el pago?';

            this.$swal({
                title: title,
                icon: 'question'
            }).then(result => {
                if (result.value) {
                    this.isLoading = true;
                    this.axios.post(paymentVerifyUrl(), payment).then(response => {
                        if (response.data.code === constants.CODE_OK) {
                            this.$root.$bvToast.toast(response.data.message, {
                                title: this.swalTitle,
                                variant: 'success'
                            });

                            this.loadData();
                        } else {
                            this.$bvToast.toast(response.data.message, {
                                title: this.swalTitle,
                                variant: 'danger'
                            });
                        }
                    }).catch(() => {
                        this.$bvToast.toast('Ocurrió un error al guardar los cambios.', {
                            title: this.swalTitle,
                            variant: 'danger'
                        });
                    }).finally(() => {
                        this.isLoading = false;
                    });
                }
            });
        },
        paymentPage(payment) {
            this.isLoading = true;
            this.axios.post(paymentPageUrl(), payment).then(response => {
                let link = document.createElement('a');

                document.body.appendChild(link);

                link.setAttribute('type', 'application/pdf');
                link.href = 'data:application/pdf;base64,' + response.data.file;
                link.download = this.payment.folio;
                link.click();

                document.body.removeChild(link);
            }).catch(() => {
                this.$bvToast.toast('Ocurrió un error al generar el comprobante de pago.', {
                    variant: 'danger'
                });
            }).finally(() => {
                this.isLoading = false;
            });
        },
        cancelPayment(payment) {
            let title = '¿Cancelar el pago?';

            this.$swal({
                title: title,
                icon: 'question',
                text: 'Razón de la cancelación: ',
                input: 'text',
                inputValidator: value => {
                    if (!value) {
                        return 'Es necesario ingresar una razón';
                    }
                }
            }).then(result => {
                if (result.value) {
                    this.isLoading = true;
                    payment.cancelNotes = result.value;

                    this.axios.post(paymentCancelUrl(), payment).then(response => {
                        if (response.data.code === constants.CODE_OK) {
                            this.$root.$bvToast.toast(response.data.message, {
                                title: this.swalTitle,
                                variant: 'success'
                            });

                            this.loadData();
                        } else {
                            this.$bvToast.toast(response.data.message, {
                                title: this.swalTitle,
                                variant: 'danger'
                            });
                        }
                    }).catch(() => {
                        this.$bvToast.toast('Ocurrió un error al guardar los cambios.', {
                            title: this.swalTitle,
                            variant: 'danger'
                        });
                    }).finally(() => {
                        this.isLoading = false;
                    });
                }
            });
        },
        validateForm() {
            this.$refs.observer.validate().then(success => {
                if (success) {
                    let validatePayment = true;

                    if (this.paymentsAmount > this.businessOrder.total) {
                        validatePayment = false;
                    }

                    if (validatePayment) {
                        this.savePayment();
                    } else {
                        this.$bvToast.toast('El importe ingresado no puede ser mayor al monto de la cuenta', {
                            variant: 'danger'
                        });
                    }
                }
            });
        },
        savePayment() {
            let title = '¿Guardar los pagos?';

            this.$swal({
                title: title,
                icon: 'question'
            }).then(result => {
                if (result.value) {
                    let payments = this.businessOrder.payments.filter(item => !item.id);

                    if (payments.length > 0) {
                        this.isLoading = true;
                        this.axios.post(paymentUrl(), payments).then(response => {
                            if (response.data.code === constants.CODE_OK) {
                                this.$root.$bvToast.toast(response.data.message, {
                                    title: this.swalTitle,
                                    variant: 'success'
                                });

                                this.loadData();
                            } else {
                                this.$bvToast.toast(response.data.message, {
                                    title: this.swalTitle,
                                    variant: 'danger'
                                });
                            }
                        }).catch(() => {
                            this.$bvToast.toast('Ocurrió un error al guardar los cambios.', {
                                title: this.swalTitle,
                                variant: 'danger'
                            });
                        }).finally(() => {
                            this.isLoading = false;
                        });
                    } else {
                        this.$bvToast.toast('No hay pagos nuevos por guardar', {
                            title: this.swalTitle,
                            variant: 'success'
                        });
                    }
                }
            });
        },
        closeBusinessOrder() {
            this.$swal({
                title: '¿Cerrar la reservación?',
                icon: 'question'
            }).then(result => {
                if (result.value) {
                    let oldStatus = this.businessOrder.status;

                    this.isLoading = true;
                    this.businessOrder.status = 'CLOSED';

                    this.axios.post(businessOrderUrl(), this.businessOrder).then(response => {
                        if (response.data.code === constants.CODE_OK) {
                            this.$root.$bvToast.toast(response.data.message, {
                                title: this.swalTitle,
                                variant: 'success'
                            });

                            this.loadData();
                        } else {
                            this.businessOrder.status = oldStatus;
                            this.$bvToast.toast(response.data.message, {
                                title: this.swalTitle,
                                variant: 'danger'
                            });
                        }
                    }).catch(() => {
                        this.$bvToast.toast('Ocurrió un error al guardar los cambios.', {
                            title: this.swalTitle,
                            variant: 'danger'
                        });
                    }).finally(() => {
                        this.isLoading = false;
                    });
                }
            });
        },
        cancelBusinessOrder() {
            let title = '¿Cancelar la reservación?';

            this.$swal({
                title: title,
                icon: 'question',
                text: 'Razón de la cancelación: ',
                input: 'text',
                inputValidator: value => {
                    if (!value) {
                        return 'Es necesario ingresar una razón';
                    }
                }
            }).then(result => {
                if (result.value) {
                    this.isLoading = true;
                    this.businessOrder.cancelNotes = result.value;

                    this.axios.post(businessOrderCancelUrl(), this.businessOrder).then(response => {
                        if (response.data.code === constants.CODE_OK) {
                            this.$root.$bvToast.toast(response.data.message, {
                                title: this.swalTitle,
                                variant: 'success'
                            });

                            this.loadData();
                        } else {
                            this.$bvToast.toast(response.data.message, {
                                title: this.swalTitle,
                                variant: 'danger'
                            });
                        }
                    }).catch(() => {
                        this.$bvToast.toast('Ocurrió un error al guardar los cambios.', {
                            title: this.swalTitle,
                            variant: 'danger'
                        });
                    }).finally(() => {
                        this.isLoading = false;
                    });
                }
            });
        },
        businessOrderPage() {
            this.isLoading = true;
            this.axios.post(businessOrderPageUrl(), this.businessOrder).then(response => {
                let link = document.createElement('a');

                document.body.appendChild(link);

                link.setAttribute('type', 'application/pdf');
                link.href = 'data:application/pdf;base64,' + response.data.file;
                link.download = this.businessOrder.folio;
                link.click();

                document.body.removeChild(link);
            }).catch(() => {
                this.$bvToast.toast('Ocurrió un error al generar la hoja de reservación.', {
                    variant: 'danger'
                });
            }).finally(() => {
                this.isLoading = false;
            });
        },
        transactionPage(paymentItem) {
            this.isLoading = true;
            this.axios.post(transactionPageUrl(), { transactionId: paymentItem.transactionId }).then(response => {
                let link = document.createElement('a');

                document.body.appendChild(link);

                link.setAttribute('type', 'application/pdf');
                link.href = 'data:application/pdf;base64,' + response.data.file;
                link.download = paymentItem.transactionFolio;
                link.click();

                document.body.removeChild(link);
            }).catch(() => {
                this.$bvToast.toast('Ocurrió un error al generar comprobante de transacción.', {
                    variant: 'danger'
                });
            }).finally(() => {
                this.isLoading = false;
            });
        }
    }
};
</script>

<style scoped>
    .fade-enter-active,
    .fade-leave-active {
        transition: transform 0.5s;
    }

    .fade-enter,
    .fade-leave-to {
        transform: translateX(-120%);
    }

    .fade-enter-to,
    .fade-leave {
        transform: translateX(0);
    }

    .custom-control-label {
        color: #ffffff !important;
    }
</style>